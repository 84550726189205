import React from "react";
import "./App.css";
import Form2 from "./Form2";

function App() {
  const searchParams = new URLSearchParams(document.location.search);

  const first_name = searchParams.get("first_name");

  const last_name = searchParams.get("last_name");

  const email = searchParams.get("email");

  const mobile = searchParams.get("mobile");

  // const program = searchParams.get("program");

  let program;

  if (searchParams.get("program")) {
    program =
      searchParams.get("program").length <= 4
        ? searchParams.get("program").toLowerCase()
        : searchParams.get("program");
  }

  // const university = searchParams.get("university");
  let university;

  if (searchParams.get("university")) {
    university =
      searchParams.get("university").length <= 3
        ? searchParams.get("university").toLowerCase()
        : searchParams.get("university");
  }

  const uni = searchParams.get("uni")
    ? searchParams.get("uni").toLowerCase()
    : "";

  // const mode = searchParams.get("mode");

  let mode;

  if (searchParams.get("mode")) {
    mode =
      searchParams.get("mode").length <= 3
        ? searchParams.get("mode").toLowerCase()
        : searchParams.get("mode");
  }

  // const country = searchParams.get("country");

  let country;

  if (searchParams.get("country")) {
    country =
      searchParams.get("country").length <= 3
        ? searchParams.get("country").toUpperCase()
        : searchParams.get("country");
  }

  const query = {
    first_name,
    last_name,
    email,
    mobile,
    program,
    university,
    uni,
    mode,
    country,
  };

  return (
    <>
      <nav>
        <img
          src="https://studyhq.com/wp-content/uploads/2022/11/cropped-studyhq-1.jpeg"
          alt="studyHq"
          className="logo-image"
        />
      </nav>

      <Form2 query={query} />
    </>
  );
}

export default App;
